import React, { useState, useEffect, useCallback } from "react"

import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"

const PrettoSlider = styled(Slider)({
  color: "#36c5f1",
  height: 15,
  width: "95%",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-markLabel": {
    color: "white",
    paddingTop: "5px",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 42,
    height: 42,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#098db6",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
})

const valueLabelFormat = value => `$${value / 1000}K`

const valuetext = value => `$${value / 1000}K`

const marks = [
  {
    value: 1000,
    label: "$1K",
  },
  {
    value: 10000,
    label: "$10K",
  },
  {
    value: 20000,
    label: "$20K",
  },
  {
    value: 30000,
    label: "$30K",
  },
  {
    value: 40000,
    label: "$40K",
  },
  {
    value: 50000,
    label: "$50K",
  },
]

const BudgetSlider = ({ budget, setBudget }) => {
  // const [budget, setBudget] = useState(10000)

  const handleSliderChange = (event, newValue) => {
    setBudget(newValue)
  }

  return (
    <PrettoSlider
      value={budget}
      onChange={handleSliderChange}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      marks={marks}
      min={1000}
      step={1000}
      max={50000}
      name="budget"
      getAriaValueText={valueLabelFormat}
      valueLabelFormat={valueLabelFormat}
    />
  )
}

export default BudgetSlider
