import { Link } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Title from '../components/title'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Menu = ({page}) => {

    const [visible, setVisible] = useState('nav');
    // We need to use useRef and .current because useState wasn't working for some reason
    const prevScrollpos = useRef((typeof window !== "undefined" ? window.pageYOffset : 0));

    useEffect(() => { 
      const handleScroll = () => {
          let currentScrollPos = window.pageYOffset;
          setVisible( ( prevScrollpos.current < currentScrollPos ? 'nav navbar--hidden' : 'nav') );
          prevScrollpos.current = currentScrollPos;
      };

      if (typeof window !== "undefined") {
        window.addEventListener("scroll", handleScroll); 
      }

      return () => { 
        if (typeof window !== "undefined") {
          window.removeEventListener("scroll", handleScroll);
        } 
      }
    }, []);

    const navClick = (e) => {
      trackCustomEvent({
          category: "Navigation",
          action: "Click",
          label: e.target.textContent,
          value: 3
      })
    }

    return (
    <div className={visible}>
        <div> <Title /> </div>
        <nav>
            <Link to="/" className="navLink" activeClassName="selected" onClick={navClick}>HOME</Link>
            <Link to="/about" className="navLink" activeClassName="selected" onClick={navClick}>ABOUT</Link>
            <Link to="/pricing" className="navLink" activeClassName="selected" onClick={navClick}> PRICING </Link>
            {/* <Link to="/samples" className="navLink" activeClassName="selected" onClick={navClick}> SAMPLES </Link> */}
        </nav>
        
    </div>
    )
}

Menu.propTypes = {
    siteTitle: PropTypes.string,
  }
  
  Menu.defaultProps = {
    siteTitle: ``,
  }

export default Menu
