import React, {useState, useEffect} from 'react'
import MyCard from '../components/card'
import left from '../images/icons/chevron-circle-left.svg'
import right from '../images/icons/chevron-circle-right.svg'
import ScrollIcon from '../components/scroll'

const Pricing = () => {

    const [visibleCard, setVisibleCard] = useState(0);

    const clickRight = () => {
        let cards = document.getElementsByClassName("cardBox");
        if (visibleCard === 0) {
            cards[0].style.display = "none";
            cards[1].style.display = "inline-block";
            cards[2].style.display = "none";
            setVisibleCard(1);
        } else if (visibleCard === 1) {
            cards[0].style.display = "none";
            cards[1].style.display = "none";
            cards[2].style.display = "inline-block";
            setVisibleCard(2);
        } else if (visibleCard === 2) {
            cards[0].style.display = "inline-block";
            cards[1].style.display = "none";
            cards[2].style.display = "none";
            setVisibleCard(0);
        }
    }

    const clickLeft = () => {
        let cards = document.getElementsByClassName("cardBox");
        if (visibleCard === 2) {
            cards[0].style.display = "none";
            cards[1].style.display = "inline-block";
            cards[2].style.display = "none";
            setVisibleCard(1);
        } else if (visibleCard === 1) {
            cards[0].style.display = "inline-block";
            cards[1].style.display = "none";
            cards[2].style.display = "none";
            setVisibleCard(0);
        } else if (visibleCard === 0) {
            cards[0].style.display = "none";
            cards[1].style.display = "none";
            cards[2].style.display = "inline-block";
            setVisibleCard(2);
        }
    }

    useEffect( () => { 
        const updateCardView = () => {
            let cards = document.getElementsByClassName("cardBox");
            if(window.innerWidth > 600) {
                cards[0].style.display = "inline-block";
                cards[1].style.display = "inline-block";
                cards[2].style.display = "inline-block";
            } else {
                cards[0].style.display = "inline-block";
                cards[1].style.display = "none";
                cards[2].style.display = "none";
            }
        }

        if (typeof window !== "undefined") {
            window.addEventListener("resize", updateCardView); 
        }
        
        return () => { 
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", updateCardView); 
            }
        }
    }, []);

    return (
        <div className="container" id="pricing" >
            <div className="row">
                <div className="col" >
                    <div className='background-text' >
                        <h2>Website Packages</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col cardBox" >
                    <MyCard title="Landing Page" price="500" list="basic" />
                </div>
                <div className="col cardBox" >
                    <MyCard title="Website" price="1000" list="pro" />
                </div>
                <div className="col cardBox" >
                    <MyCard title="E-Commerce" price="1500" list="ecom" />
                </div>
                <div id="slideLeft"><img alt="Previous" className="icon" src={left} onClick={clickLeft} /></div>
                <div id="slideRight"><img alt="Next" className="icon" src={right} onClick={clickRight} /></div>
            </div>
            <ScrollIcon />   
        </div>
    );
}

export default Pricing