import { Link } from "gatsby"
import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Title = () => {
    const navClick = (e) => {
        trackCustomEvent({
            category: "Navigation",
            action: "Click",
            label: e.target.textContent,
            value: 3
        })
      }
    return (
        <Link to="/" className='navTitle' onClick={navClick} > 
            <span className='navTitleLeft'>Rethink</span>
            <span className='navTitleRight' >Main</span>  
        </Link>
    )
}
  export default Title