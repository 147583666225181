import React from 'react'
import MyButton from '../components/button'
import Pricing from '../components/pricing'
import About from '../components/about'
import ScrollIcon from '../components/scroll'

const Foreground= (props) => {
    if (props.page === 'pricing') {
        return ( <Pricing /> )
    } else if (props.page === 'about') {
        return ( <About /> )    
    } else if (props.page === '404') {
        return (
            <div className='background-text' >
                <h1>Oops! Page not found</h1>
                <p>¯\_(ツ)_/¯</p>
                <p>Looks like you're in the wrong place</p>
                <MyButton text="Take me home" type="404" className="blueButton" />
                <ScrollIcon />
            </div>
        )
    } else {
        return (
            <div className='background-text' >
                <h1>Take your business online</h1>
                <p>Go beyond the storefront and let the world see who you are</p>
                <MyButton text="Get Started Now" type="gotocontact" className="blueButton" />
                <ScrollIcon />
            </div>
        )
    }
    
}

export default Foreground