import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import StyledBackgroundSection from '../components/background'
import Menu from "./menu"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Footer from "./footer"
import '../css/bootstrap.min.css';
import "../css/layout.css"

const Layout = ({ children, page}) => {
  const reCaptchaKey="6Lem9-kUAAAAAAaI_J3a6OU_xbvwZNWegjCuouxG";

  const data = useStaticQuery(graphql`query SiteTitleQuery { site { siteMetadata { title } } }`)
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} >
      <Menu siteTitle={data.site.siteMetadata.title} page={page} />
      <StyledBackgroundSection className='bgImage' page={page} />
      <div>
        <main>{children}</main>
        <Footer />
        <div id="mobileOfset"></div>
      </div>
    </GoogleReCaptchaProvider>
  )
}

export default Layout
