import React from 'react'
import checkBlue from '../images/icons/checkBlue.svg'
import MyButton from '../components/button'

const MyCard = ({title, price, list}) => {
    const blueCheck = <img alt="Check" className="iconSmall" src={checkBlue} />

    const cardData = {
        basic: [
            {id: 1, name: "1 domain name"}, 
            {id: 2, name: "1 business email address"}, 
            {id: 3, name: "Custom landing page"},
            {id: 4, name: "Contact form section"},
            {id: 5, name: "Email optin"},
            {id: 6, name: <MyButton text="Learn more..." type="gotowhat" className="smallButton" />}
        ],
        pro: [
            {id: 1, name: "Included landing page package"}, 
            {id: 2, name: "Up to 5 business emails addresses"}, 
            {id: 3, name: "Up To 5 Additional Pages"},
            {id: 4, name: "Content & copywriting service"},
            {id: 5, name: <MyButton text="Learn more..." type="gotowhat" className="smallButton" />}
        ],
        ecom: [
            {id: 1, name: "Included website package"}, 
            {id: 2, name: "Shopify store setup"},
            {id: 3, name: "Up to 5 business emails addresses"}, 
            {id: 4, name: "Up to 10 hours of customizations"},
            {id: 5, name: <MyButton text="Learn more..." type="gotowhat" className="smallButton" />}
        ]
    }

    let cardList = cardData[list].map((item, key) =>
        <li key={item.id}>{blueCheck} {item.name} {item.info}</li>
    );
    return (
        <div className="mycard">
            <h3>{title}</h3>
            <div className="setup">${price}</div>
            <div className="hosting">$20 Monthly Hosting Fee</div>
            <ul>{cardList}</ul>
        </div>
    )
};

export default MyCard