import React from 'react'
import Image from '../components/image'
import ScrollIcon from '../components/scroll'

const About = () => (
    <div id="about">
    <div className="container" style={{height: '100%'}}>
        <div className="row" style={{height: '100%'}}>
            <div className="col" id="aboutImage" >
                <Image imageName="laptopImage" objectFit="cover" />
            </div>
            <div className="col" id="aboutText" >
                <div className="row" id="aboutTitle" >
                    <div className="col">
                        <h3 style={{fontSize: '1rem'}}>Our Mission</h3>
                        <hr />
                    </div>
                </div>
                <div id="aboutMission">
                    <div className="col">
                        <p className='sectionText'>
                            Small businesses are the life blood of the economy. We know you’ve heard that one before but the statement has never been truer. 
                            They are the engine that drive job growth, innovation and American competitiveness.  
                            Our mission at Rethink Main is to strengthen Main Street America one small business at a time. 
                            We will do this by providing the tools necessary to keep businesses competitive and thriving in a quickly changing marketplace. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ScrollIcon />
    </div>
);

export default About