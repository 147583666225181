import React from 'react'
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const MyButton = ({text, type, className, disabled}) => {
    const buttonClick = (e) => {
        // e.preventDefault()
        trackCustomEvent({
            category: "Buttons",
            action: "Click",
            label: e.target.textContent,
            value: 1
        })
        if (type === 'gotocontact') {
            document.getElementById("contact").scrollIntoView();
        }
        if (type === 'gotowhat') {
            document.getElementById("what").scrollIntoView();
        }
    }

    if (type === "sendcontact") {
        return ( <button className={`${className} mybutton`} type="submit" onClick={buttonClick} disabled={disabled} >{text}</button> );
    } else if (type === "gotoabout") {
        return ( <Link to="/about"><button className={`${className} mybutton`} onClick={buttonClick} >{text}</button></Link> );
    } else if (type === "404") {
        return ( <Link to="/"><button className={`${className} mybutton`} onClick={buttonClick} >{text}</button></Link> );
    } else {
        return ( <button className={`${className} mybutton`} onClick={buttonClick} >{text}</button> );
    }
    
};

export default MyButton