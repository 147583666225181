import React, { useState, useEffect, useCallback } from "react"
import MyButton from "../components/button"
import BudgetSlider from "../components/slider"

import Grid from "@mui/material/Grid"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

import Swal from "sweetalert2"
import Spinner from "../components/spinner"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const ContactUs = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [token, settoken] = useState("")

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available")
      return
    }

    const token = await executeRecaptcha("Contact")
    settoken(token)
    // Do whatever you want with the token
  }, [])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  // For Form Validation
  const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/
  const nameRegex = /^[a-z ,.'-]+$/i
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

  // Modal Data
  const modalSuccess = {
    title: "Thank You!",
    message: "We have received your message and will get back to you ASAP",
  }
  const modalFail = {
    title: "Sorry!",
    message:
      "An unexpected error occurred. We will work to fix this right away. Until then please feel free to email us at: ",
  }
  const modalOops = {
    title: "Oops!",
    message:
      "Looks like some fields are filled incorrectly. Please fix and try again",
  }

  const budgetDefault = 10000

  // Form State
  const [isLoadeding, setisLoadeding] = useState(false)

  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [email, setemail] = useState("")
  const [phone, setphone] = useState("")
  const [textArea, settextArea] = useState("")

  const [budget, setBudget] = useState(budgetDefault)

  const [design, setDesign] = useState(false)
  const [programming, setProgramming] = useState(false)
  const [marketing, setMarketing] = useState(false)

  const [errfirstName, seterrfirstName] = useState("")
  const [errlastName, seterrlastName] = useState("")
  const [erremail, seterremail] = useState("")
  const [errphone, seterrphone] = useState("")
  const [errtextArea, seterrtextArea] = useState("")

  const handleChange = e => {
    e.preventDefault()

    const { name, value, checked } = e.target

    switch (name) {
      case "firstName":
        setfirstName(value)
        break
      case "lastName":
        setlastName(value)
        break
      case "email":
        setemail(value)
        break
      case "phone":
        setphone(value)
        break
      case "textArea":
        settextArea(value)
        break
      case "design":
        setDesign(checked)
        break
      case "programming":
        setProgramming(checked)
        break
      case "marketing":
        setMarketing(checked)
        break
      default:
        break
    }
  }

  const handleBlur = e => {
    e.preventDefault()

    const { name, value } = e.target

    switch (name) {
      case "firstName":
        seterrfirstName(
          value.length < 2
            ? "Minimum 2 characaters required"
            : nameRegex.test(value)
            ? ""
            : "Invalid characaters"
        )
        break
      case "lastName":
        seterrlastName(
          value.length < 2
            ? "Minimum 2 characaters required"
            : nameRegex.test(value)
            ? ""
            : "Invalid characaters"
        )
        break
      case "email":
        seterremail(
          value.length < 3
            ? "Minimum 3 characaters required"
            : emailRegex.test(value)
            ? ""
            : "Invalid email address"
        )
        break
      case "phone":
        seterrphone(
          value.length === 0 || phoneRegex.test(value)
            ? ""
            : "Invalid phone number"
        )
        break
      case "textArea":
        seterrtextArea(
          value.length < 10 ? "Minimum 10 characaters required" : ""
        )
        break
      default:
        break
    }
  }

  const validate = () => {
    let error = false
    // IF Requiered Fields Are Empty Then Add Errors
    if (firstName === "") {
      seterrfirstName("First name is required")
      error = true
    }
    if (lastName === "") {
      seterrlastName("Last name is required")
      error = true
    }
    if (email === "") {
      seterremail("Email is required")
      error = true
    }
    if (textArea === "") {
      seterrtextArea("Message is required")
      error = true
    }
    if (error) return false

    // Check For Errors
    if (errfirstName !== "") return false
    if (errlastName !== "") return false
    if (erremail !== "") return false
    if (errphone !== "") return false
    if (errtextArea !== "") return false

    return true
  }

  const resetForm = () => {
    setfirstName("")
    setlastName("")
    setemail("")
    setphone("")
    settextArea("")
    setBudget(budgetDefault)
    setDesign(false)
    setProgramming(false)
    setMarketing(false)
  }

  const submitForm = e => {
    e.preventDefault()
    setisLoadeding(true)

    if (validate()) {
      fetch(
        "https://ikybdq4zo4.execute-api.us-east-1.amazonaws.com/prod/contact",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            email: email.trim(),
            phone: phone.trim(),
            textArea: textArea.trim(),
            budget: budget,
            services: {
              design: design,
              programming: programming,
              marketing: marketing,
            },
            token: token,
          }),
        }
      )
        .then(res => res.json())
        .then(
          result => {
            setisLoadeding(false)
            Swal.fire(modalSuccess.title, modalSuccess.message)
            resetForm()
            trackCustomEvent({
              category: "Contact Forms",
              action: "Submit",
              label: "Success",
              value: 2,
            })
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          error => {
            setisLoadeding(false)
            Swal.fire(modalFail.title, modalFail.message, "error")
            resetForm()
            trackCustomEvent({
              category: "Contact Forms",
              action: "Submit",
              label: "Error",
              value: 2,
            })
          }
        )
    } else {
      setisLoadeding(false)
      Swal.fire(modalOops.title, modalOops.message, "warning")
      trackCustomEvent({
        category: "Contact Forms",
        action: "Submit",
        label: "Invalid",
        value: 2,
      })
    }
  }

  return (
    <div id="contact" className="section dark-blue">
      <div className="container">
        <form onSubmit={submitForm} noValidate>
          <Grid container spacing={4}>
            <Grid item xs={12} className="sectonTitle">
              <h2>Tell us about your project</h2>
            </Grid>
            <Grid item sm={12} md={6} style={{ width: "100%" }}>
              <h5 className="left">1. Tell us who you are</h5>

              <div className="form-group">
                <small className="form-text text-danger">{errfirstName}</small>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={firstName}
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  noValidate
                />
              </div>

              <div className="form-group">
                <small className="form-text text-danger">{errlastName}</small>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  noValidate
                />
              </div>

              <div className="form-group">
                <small className="form-text text-danger">{erremail}</small>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="Email Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  noValidate
                />
              </div>

              <div className="form-group">
                <small className="form-text text-danger">{errphone}</small>
                <input
                  className="form-control"
                  type="phone"
                  name="phone"
                  id="phone"
                  value={phone}
                  placeholder="Phone Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  noValidate
                />
              </div>

              <h5 className="left">2. Tell us about your project</h5>

              <div className="form-group">
                <small className="form-text text-danger">{errtextArea}</small>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="textArea"
                  id="textArea"
                  rows="3"
                  value={textArea}
                  placeholder="Message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <h5 className="left">3. What is your budget?</h5>

              <BudgetSlider budget={budget} setBudget={setBudget} />

              <h5 className="left">
                4. Which services are you interested in?
                <span
                  style={{
                    color: "rgb(73, 80, 87)",
                    fontSize: "1rem",
                    fontWeight: "lighter",
                  }}
                >
                  &nbsp;(Select all that apply)
                </span>
              </h5>
              <div className="left">
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={design}
                          onChange={handleChange}
                          name="design"
                          style={{ color: "white" }}
                        />
                      }
                      label="Design"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={programming}
                          onChange={handleChange}
                          name="programming"
                          style={{ color: "white" }}
                        />
                      }
                      label="Programming"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={marketing}
                          onChange={handleChange}
                          name="marketing"
                          style={{ color: "white" }}
                        />
                      }
                      label="Marketing"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <MyButton
                text="Send It"
                type="sendcontact"
                className={`blueButton ${isLoadeding ? "loading" : ""} `}
                disabled={isLoadeding ? "disabled" : ""}
              />
              <Spinner isLoadeding={isLoadeding} />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  )
}
export default ContactUs
