import { Link } from "gatsby"
import React from "react"
import Image from '../components/image'
import fb from '../images/icons/fbBlue.svg'
import ig from '../images/icons/igBlue.svg'
import em from '../images/icons/emBlue.svg'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Footer = () => {
    const socialClick = (e) => {
        trackCustomEvent({
            category: "Links",
            action: "Click",
            label: e.target.dataset.type,
            value: 1
        })
    }
    return (
        <footer className="dark" >
            <div className="container" >
                <div className="row">
                    <div className="col">
                        <p><b>Site Map</b></p>
                        <Link to="/" >Home</Link><br />
                        {'\u2022'} <Link to="/#who" >Who We Are</Link><br />
                        {'\u2022'} <Link to="/#contact" >Contact Us</Link><br />
                        <br /><Link to="/about" >About Us</Link><br />
                        {'\u2022'} <Link to="/about#why" >Why Choose Us</Link><br />
                        <br /><Link to="/pricing" >Pricing</Link><br />
                        {'\u2022'} <Link to="/pricing#what" >Included With Each Package</Link><br />
                    </div>
                    <div className="col" >
                        <div >
                            <Image imageName="logoImage" objectFit="scale-down" />
                        </div> 
                        <div style={{textAlign: "center", padding: "10px 0px"}} >
                        <a onClick={socialClick} href="https://www.facebook.com/rethinkmain" rel="noopener noreferrer" target="_blank" ><img data-type="Facebook" alt="Facebook" className="icon" src={fb} style={{padding: "0px 4px"}} /></a>
                        <a onClick={socialClick} href="https://www.instagram.com/rethinkmain/" rel="noopener noreferrer" target="_blank" ><img data-type="Instagram" alt="Instagram" className="icon" src={ig} style={{padding: "0px 4px"}} /></a>
                        <a onClick={socialClick} href="mailto:info@rethinkmain.com" ><img data-type="Email" alt="Email" className="icon" src={em} style={{padding: "0px 4px"}} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
    }
  
  export default Footer